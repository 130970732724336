/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import 'swiper/css/bundle';

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    user-select: initial;
}

ion-button {
    // text-transform: none;
}

.header-md::after {
    background-image: none;
}

@layer components {
    .text-h1-grey {
        @apply font-bold;
        font-size: 22px;
        color: #b7bbc8;
    }

    .small-header {
        @apply uppercase text-[11px] text-[#828282] font-bold;
    }
}

@layer base {
    .ellipsis {
        @apply overflow-ellipsis overflow-hidden whitespace-nowrap;
    }
}

ion-content {
    --background: #ffffff;
}

ion-footer,
ion-header,
ion-toolbar {
    --background: transparent;
    box-shadow: none !important;
}

.file-viewer-modal {
    --min-width: 100%;
    --min-height: 100%;
}

// Swiper styles
swiper-container {
    --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
    --swiper-pagination-color: var(--ion-color-primary, #3880ff);
    --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
    --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
    --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
    // display: flex;
    position: relative;

    // flex-direction: column;
    flex-shrink: 0;
    // align-items: center;
    // justify-content: center;

    width: 100%;
    height: 100%;

    font-size: 18px;

    box-sizing: border-box;
}

swiper-slide img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
}

.ck-powered-by-balloon {
    display: none !important;
}
